<template>
    <div class="hk-wrapper" data-layout="horizontal" data-layout-style="default" data-menu="light" data-footer="simple">
        <!-- Top Navbar -->
        <Navbar />
        <!-- /Top Navbar -->

        <!-- Main Content -->
        <div class="hk-pg-wrapper">
            <div class="container-xxl">
                <slot />
            </div>
        </div>
        <!-- Footer -->
        <Footer />
        <!-- Footer -->

    </div>
</template>



<style></style>